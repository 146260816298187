"use client";

import GlobalError from "@common/components/Error/GlobalError";
import { GridItemContainer } from "@common/styles/index";
import { useEffect } from "react";
const LandingPageError = ({
  error
}: {
  error: Error & {
    digest?: string;
  };
}) => {
  useEffect(() => {
    console.log("_error", error);
  }, []);
  return <GridItemContainer $useGridPadding data-sentry-element="GridItemContainer" data-sentry-component="LandingPageError" data-sentry-source-file="error.tsx">
			<GlobalError title="We’re facing some technical issue." description="We’ve run into a small technical issue while loading the page. Our team is already alerted and fixing this. Kindly check the page after 10-15 minutes." data-sentry-element="GlobalError" data-sentry-source-file="error.tsx" />
		</GridItemContainer>;
};
export default LandingPageError;